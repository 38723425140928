<template>
<div>
  <b-table
    :fields="fields"
    :items="dataList||[]"
    :per-page="perPage"
    :primary-key="idKey"
    :busy="!dataList"
    responsive
    show-empty
    striped
    bordered
  >

    <template #empty>
      <div class="text-center">
        <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
        <h4 align="center"><span v-if="Object.keys(filter).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
      </div>
    </template>
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <template #cell(number)="v">
      {{(data.per_page*(data.current_page-1))+v.index+1}}
    </template>

    <template #cell(ma_title_id)="data">
      <div class="wrap_title_post">
        <h3>
          <!--:href="foLink(data.item)" target="_blank"-->
          <a href="javascript:;">
            {{ data.value }}
          </a>
        </h3>
        <ul>
          <li><i class="icon-user"></i> {{ data.item.ma_author }}</li>
          <li><i class=" ti-calendar"></i> {{ data.item.ma_publish_date | moment("LL") }}</li>
          <li><i class="icon-eye"></i> {{ data.item.ma_viewer }} views</li>
        </ul>
      </div>
    </template>

    <template
      #cell(ma_is_active) = "data"
    >
      <LabelStatus :item="data.item"/>
    </template>

    <template
      #cell(action)="data"
    >
      <b-button
        v-if="moduleRole('edit')"
        v-b-tooltip.hover
        title="Edit"
        class="btn-icon"
        variant="outline-warning"
        size="sm"
        :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
      >
        <i class="fas fa-pencil-alt"></i>
      </b-button>
      <b-button
        v-if="moduleRole('delete')"

        v-b-tooltip.hover
        title="Delete"
        class="btn-icon"
        variant="outline-danger"
        size="sm"
        @click="doDelete(data.index, data.item)"
      >
        <i class="fas fa-trash-alt"></i>
      </b-button>
    </template>

  </b-table>
</div>
</template>

<script>
import LabelStatus from './LabelStatus'

export default{
  props:{
    data:[Array, Object, Boolean],
    dataList:[Array, Object, Boolean],
    perPage:[Number, String],
    idKey:String,
    filter:Object,
    pageTitle:String
  },
  components:{LabelStatus},
  data(){
    return {
      sortBy: 'ma_title_id',
      sortDesc: false,
      fields:[
        {
          key: 'number',
          label: '#',
        },
        {
          key:'ma_title_id',
          label:'Title',
          //sortable: true
        },
        {
          key:'mac_category_id',
          label:'Category',
          //sortable: true
        },
        {
          key:'ma_is_active',
          label:'Status'
        },
        'action'
      ]
    }
  },
  computed:{
    filterData(){
      let filterData = JSON.parse(JSON.stringify(this.filter))
      delete filterData.viewMode
      delete filterData.page
      return filterData
    },
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  methods:{
    moduleRole(module){
      return this.parent.moduleRole(module)
    },
    doChangeStatus(index, value){
      return this.parent.doChangeStatus(index, value)
    },
    doDelete(index, value){
      return this.parent.doDelete(index, value)
    },
    foLink(item){
      // bypass
      return this.parent.foBaseUrl+'/'+item.ma_id
    }
  },
  watch:{
    sortBy(){
      this.parent.doSortBy()
    },
    sortDesc(){
      this.parent.doSortBy()
    },
  }
}
</script>