<template>
<b-card no-body>
  <b-tabs card vertical>
    <b-tab
      v-for="(key, no) in Object.keys(setting)"
      :key="key"
      :title="setting[key].page"
      :active="!no"
      :disabled="setting[key].page == 'Artikel' || setting[key].page == 'Berita'"
      class="p-0"
    >
      <validation-observer
        v-slot="{ handleSubmit }" :ref="'VFormSeo'+no"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitSEO(setting[key], key, 'VFormSeo'+no))">
          <b-card-body>
            <b-alert v-if="setting[key].needSubmit && parent.moduleRole('updateseopage')" variant="warning" show>
              <b>{{ parent.Config.wording.detectUpdate }}</b>
            </b-alert>
            <b-row>
              <b-col lg="6">
                <b-form-group label-for="homeMetaTitleID">
                  <template #label>
                    Meta Title (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitleID" placeholder="Meta Title" v-model="setting[key].meta_title_id" @input="setting[key]['needSubmit']=true,watchMeta($event, key,'Meta Title ID','title')" />
                  <VValidate 
                    :name="'Meta Title ID '+ key"
                    v-model="setting[key].meta_title_id" 
                    rules="required|min:3"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group label-for="homeMetaTitleEN">
                  <template #label>
                    Meta Title (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitleEN" placeholder="Meta Title" v-model="setting[key].meta_title_en" @input="setting[key]['needSubmit']=true,watchMeta($event, key,'Meta Title EN','title')" />
                  <VValidate 
                    :name="'Meta Title EN '+ key"
                    v-model="setting[key].meta_title_en" 
                    rules="required|min:3"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea id="homeMetaDescID" rows="5" placeholder="Meta Description" v-model="setting[key].meta_description_id" @input="setting[key]['needSubmit']=true,watchMeta($event, key,'Meta Description ID','desc')" />
                  <VValidate 
                    :name="'Meta Description ID '+ key"
                    v-model="setting[key].meta_description_id" 
                    rules="required|min:3"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea id="homeMetaDescEn" rows="5" placeholder="Meta Description" v-model="setting[key].meta_description_en" @input="setting[key]['needSubmit']=true,watchMeta($event, key, 'Meta Description EN','desc')" />
                  <VValidate 
                    :name="'Meta Description EN '+ key" 
                    v-model="setting[key].meta_description_en" 
                    rules="required|min:3"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Keywords (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTagsID" placeholder="Type and press enter ..." remove-on-delete
                    tag-variant="success" tag-class="text-white" v-model="setting[key].meta_keyword_id" @input="setting[key]['needSubmit']=true" />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Keywords (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTagsEN" placeholder="Type and press enter ..." remove-on-delete
                    tag-variant="success" tag-class="text-white" v-model="setting[key].meta_keyword_en" @input="setting[key]['needSubmit']=true" />
                </b-form-group>
              </b-col>
            </b-row>

          </b-card-body>
          <b-card-footer v-if="parent.moduleRole('updateseopage')">
            <div class="text-right">
              <b-button class="btn-rounded" type="submit" variant="success">Simpan Perubahan</b-button>
            </div>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-tab>
  </b-tabs>
</b-card>
</template>
<script>
import Gen from '@/libs/Gen'

export default{
  props:{ setting:Object },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    }
  },
  data(){
    return {
      heroEditorOpen:false,
      input:{}
    }
  },
  methods:{
    watchMeta(v, key, id, type){
      if(type == 'title'){
        let showcount = document.getElementById(id +" "+ key + 'count')
        let cErr = document.getElementById(id +" "+ key + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"       
          showcount.style.display = "none"        
        }
      }
      else {
        let showcount = document.getElementById(id +" "+ key + 'count')
        let cErr = document.getElementById(id +" "+ key + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"       
          showcount.style.display = "none"        
        }
      }
    },
    doSubmitSEO(input, key, VForm){
      this.$refs[VForm][0].validate().then(success => {
        if (!success) return

        input.needSubmit=false
        
        this.parent.loadingOverlay = true 
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {type:'update-page-seo'})}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data

            let setObj = this.setting
            setObj[key] = input
            this.$emit('update:setting', setObj)

            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            setTimeout(()=>{ this.message="" },15000)
        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    }
  }
}
</script>