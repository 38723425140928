<template>
<span>
  <b-badge v-if="item.ma_is_locked=='P'" variant="danger" :class="{'post-block__badge':mode=='grid'}">Is Locked</b-badge>

  <b-badge v-if="item.ma_is_active=='P'" variant="success" :class="{'post-block__badge':mode=='grid'}">Published</b-badge>
  <b-badge v-else-if="item.ma_is_active=='D'" variant="secondary" :class="{'post-block__badge':mode=='grid'}">Draft</b-badge>
  <b-badge v-else-if="item.ma_is_active=='N'" variant="danger" :class="{'post-block__badge':mode=='grid'}">Not Published</b-badge>
  <b-badge v-else-if="item.ma_is_active=='S'" variant="info" :class="{'post-block__badge':mode=='grid'}">Scheduled</b-badge>
</span>
</template>

<script>
export default{
  props:{
    item:Object,
    mode:{
      type:String,
      default:"table"
    }
  }
}
</script>