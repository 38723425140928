<template>
<validation-observer
  ref="VForm"
>
  <b-form @submit.prevent="doSubmitArticle">
    <b-tabs v-if="mrValidation" v-model="tabIndex">
      <b-tab title="Article Content">
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col lg="6">
                <b-form-group label-for="articleCat">
                  <template #label>Category<span class="text-danger mr5">*</span></template>
                  <v-select
                    id="articleCat" 
                    placeholder="Select Category"
                    v-model="row.ma_category"
                    :options="mrCategory"
                    :reduce="v => v.value"
                  />
                  <VValidate 
                    name="Category" 
                    v-model="row.ma_category" 
                    :rules="mrValidation.ma_category" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="9">
                <b-card no-body class="border">
                  <b-tabs card v-model="tabIndexContent">
                    <b-tab title="Content (ID)">
                      <b-form-group label-for="articleTitle">
                        <template #label>Title<span class="text-danger mr5">*</span></template>
                        <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" id="articleTitle" v-model="row.ma_title_id" placeholder="e.g Indonesia has many culture" />
                        <VValidate 
                          name="Title ID" 
                          v-model="row.ma_title_id" 
                          :rules="{...mrValidation.ma_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleContent">
                        <template #label>Content <span class="text-danger mr5">*</span></template>
                        <CKEditor 
                          id="articleContent"
                          :value.sync="row.ma_desc_id"
                          :customToolbar="itemsToolbar"
                        />
                        <VValidate 
                          name="Content ID" 
                          v-model="row.ma_desc_id" 
                          :rules="mrValidation.ma_desc_id" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleTags">
                        <template #label>Tags <span v-if="row.ma_is_active == 'P'" class="text-danger mr5">*</span></template>
                        <b-form-tags v-model="row.ma_tags_id" id="articleTags" remove-on-delete tag-variant="success" tag-class="text-white"/>
                        <VValidate 
                          name="Tags ID" 
                          :value="((row.ma_tags_id||[]).length?1:'')" 
                          :rules="{required: row.ma_is_active == 'P' ? true : false}" 
                        />
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Content (EN)">
                      <b-form-group label-for="articleTitle">
                        <template #label>Title<span class="text-danger mr5">*</span></template>
                        <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" id="articleTitle" v-model="row.ma_title_en" placeholder="e.g Indonesia has many culture" />
                        <VValidate 
                          name="Title EN" 
                          v-model="row.ma_title_en" 
                          :rules="{...mrValidation.ma_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleContent">
                        <template #label>Content <span class="text-danger mr5">*</span></template>
                        <CKEditor 
                          id="articleContent"
                          :value.sync="row.ma_desc_en"
                          :customToolbar="itemsToolbar"
                        />
                        <VValidate 
                          name="Content EN" 
                          v-model="row.ma_desc_en" 
                          :rules="mrValidation.ma_desc_en" 
                        />
                      </b-form-group>
                      <b-form-group label-for="articleTags">
                        <template #label>Tags <span v-if="row.ma_is_active == 'P'" class="text-danger mr5">*</span></template>
                        <b-form-tags v-model="row.ma_tags_en" id="articleTags" remove-on-delete tag-variant="success" tag-class="text-white"/>
                        <VValidate 
                          name="Tags EN" 
                          :value="((row.ma_tags_en||[]).length?1:'')" 
                          :rules="{required: row.ma_is_active == 'P' ? true : false}" 
                        />
                      </b-form-group>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>

              <b-col lg="3">
                <b-row>
                  <b-col lg="12" class="mb-2">
                    <b-form-group>
                      <template #label>Article Thumbnail <span v-if="row.ma_is_active == 'P'" class="text-danger ml5">*</span></template>
                      <Uploader v-model="row.ma_img" type="blog-content"/>
                      <VValidate 
                        name="Article Thumbnail" 
                        v-model="row.ma_img"
                        :rules="{required: row.ma_is_active == 'P' ? true : false}" 
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" class="mb-2">
                    <b-form-group>
                      <label>Alt Image (ID)<span class="text-danger mr5">*</span></label>
                      <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" v-model="row.ma_alt_id" placeholder="e.g Image title">
                      </b-form-input>
                      <VValidate 
                        name="Alt Image ID" 
                        v-model="row.ma_alt_id" 
                        :rules="{...mrValidation.ma_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" class="mb-2">
                    <b-form-group>
                      <label>Alt Image (EN)<span class="text-danger mr5">*</span></label>
                      <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" v-model="row.ma_alt_en" placeholder="e.g Image title">
                      </b-form-input>
                      <VValidate 
                        name="Alt Image EN" 
                        v-model="row.ma_alt_en" 
                        :rules="{...mrValidation.ma_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" class="mb-2">
                    <b-form-group label-for="articleAuthor">
                      <template #label>Author<span class="text-danger mr5">*</span></template>
                      <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar"
                        id="articleAuthor"
                        v-model="row.ma_author"
                        placeholder="e.g Administrator"
                      />
                      <VValidate 
                        name="Author" 
                        v-model="row.ma_author" 
                        :rules="{...mrValidation.ma_author, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />
                    </b-form-group>
                  </b-col>

                  <!--
                  <b-col lg="12" class="mb-2">
                    <b-form-group label-for="articleStatus">
                      <b-form-checkbox
                        id="checkbox-1"
                        name="checkbox-1"
                        v-model="row.is_status_draft"
                      >
                        Save as draft
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  -->

                  <b-col lg="12" class="mb-2">
                    <b-form-group label-for="articlePubDate">
                      <template #label>Publish Date<span class="text-danger mr5">*</span></template>
                      <div class="input-group mb-3">
                        <datepicker input-class="form-control transparent" v-model="row.ma_publish_date" :disabled="row.is_status_draft||false" placeholder="Select Publish Date"></datepicker>
                        <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                        </div>
                      </div>

                      <VValidate 
                        v-if="!row.is_status_draft"
                        name="Publish Date" 
                        v-model="row.ma_publish_date" 
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" class="h-200">
                    <b-form-group label-cols-md="3" label="Status">
                      <b-badge v-if="blogStatus=='P'" variant="success" class="post-block__badge">Published</b-badge>
                      <b-badge v-else-if="blogStatus=='D'" variant="secondary" class="post-block__badge">Draft</b-badge>
                      <b-badge v-else-if="blogStatus=='S'" variant="info" class="post-block__badge">Scheduled</b-badge>
                      <b-badge v-else-if="blogStatus=='N'" variant="danger" class="post-block__badge">Inactive</b-badge>
                      <b-badge v-else variant="light" class="post-block__badge">Not Input Yet</b-badge>
                    </b-form-group>

                    <b-form-group>                   
                    <div class="row">
                      <div class="col-md-3">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio1" v-model="row.ma_is_active" value="P"
                            name="radioRow" class="custom-control-input">
                          <label class="custom-control-label" for="customRadio1">Active</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio2" v-model="row.ma_is_active" value="D"
                            name="radioRow" class="custom-control-input">
                          <label class="custom-control-label" for="customRadio2">Draft</label>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio3" v-model="row.ma_is_active" value="N"
                            name="radioRow" class="custom-control-input">
                          <label class="custom-control-label" for="customRadio3">Inactive</label>
                        </div>
                      </div>
                      <VValidate name="Status" v-model="row.ma_is_active"
                        :rules="'required'" />
                    </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="SEO Settings">
        <b-card no-body>
          <b-card-header>
            <b-card-title title-tag="h5">Blog List SEO Settings</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="6">
                <b-form-group label-for="homeMetaTitle">
                  <template #label>
                    Meta Title (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ma_meta_title_id" />
                  <VValidate 
                    name="Meta Title ID" 
                    v-model="row.ma_meta_title_id" 
                    :rules="{...mrValidation.ma_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label-for="homeMetaTitle">
                  <template #label>
                    Meta Title (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.ma_meta_title_en" />
                  <VValidate 
                    name="Meta Title EN" 
                    v-model="row.ma_meta_title_en" 
                    :rules="{...mrValidation.ma_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.ma_meta_description_id" />
                  <VValidate 
                    name="Meta Desc ID" 
                    v-model="row.ma_meta_description_id" 
                    :rules="{...mrValidation.ma_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.ma_meta_description_en" />
                  <VValidate 
                    name="Meta Desc EN" 
                    v-model="row.ma_meta_description_en" 
                    :rules="{...mrValidation.ma_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />
                </b-form-group>
              </b-col>
            </b-row>


            <b-row>
              <b-col lg="6">
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Keywords (ID)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.ma_meta_keyword_id" />
                  
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Keywords (EN)
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.ma_meta_keyword_en" />
                  
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card no-body>
      <template #footer>
        <div class="text-right">
          <b-button class="btn-rounded" variant="transparent"  @click="$router.back()">Cancel</b-button>
          <b-button class="btn-rounded" variant="success" type="submit">Save Changes</b-button>
        </div>
      </template>
    </b-card>
  </b-form>
</validation-observer>
</template>

<script>
import CKEditor from '@/components/CKEditor'
import Datepicker from 'vuejs-datepicker'

export default{
  components:{ CKEditor, Datepicker },
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mrCategory:Array,
  },
  data(){
    return {
      tabIndex: 0,
      tabIndexContent: 0,
      itemsToolbar: [
        'heading',
        '|',
        'sourceEditing',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'uploadImage',
        'blockQuote',
        '|', 
        'alignment',
        '|', 
        'insertTable','mediaEmbed',
        '|', 
        'outdent', 'indent',
        '|',
        'undo',
        'redo'
      ]
    }
  },
  computed:{
    blogStatus(){
      if(!this.row.ma_publish_date && !this.row.ma_is_active) return ""
      else if(this.row.ma_is_active == "D") return "D"
      else if(this.row.ma_is_active == "N") return "N"
      const dateNow = new Date()
      if(new Date(this.row.ma_publish_date) > dateNow) return "S"
      else return "P"
    }
  },
  methods:{
    doSubmitArticle(){
      if(!this.row.ma_title_id || !this.row.ma_desc_id || !this.row.ma_tags_id){
        this.tabIndex = 0        
        this.tabIndexContent = 0
      }
      else if(!this.row.ma_title_en || !this.row.ma_desc_en || !this.row.ma_tags_en){
        this.tabIndexContent = 1
        this.tabIndex = 0              
      } 
      else if(!this.row.ma_category || !this.row.ma_alt_id || !this.row.ma_alt_en || !this.row.ma_publish_date || !this.row.ma_author || !this.row.ma_img){
        this.tabIndex = 0        
      }
      else{
        if(!this.row.ma_meta_title_id || !this.row.ma_meta_title_en || !this.row.ma_meta_keyword_id || !this.row.ma_meta_keyword_en ||!this.row.ma_meta_description_id || !this.row.ma_meta_description_en){
          this.tabIndex = 1
        }
      }
      
      this.$set(this.row, 'blogStatus', this.blogStatus)
      this.$parent.doSubmitCRUD('VForm', this.$refs)
    }
  },
  watch:{
    'row.ma_title_id'(v){
      if(this.$isAdd){
        this.row.ma_meta_title_id = v
        this.row.ma_alt_id = v
      }
    },
    'row.ma_title_en'(v){
      if(this.$isAdd){
        this.row.ma_meta_title_en = v
        this.row.ma_alt_en = v
      }
    },
    'row.ma_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'row.ma_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'row.ma_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'row.ma_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
  }
}
</script>